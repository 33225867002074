import classNames from "classnames"
import { TickIcon } from "components/Icons/TickIcon/TickIcon"
import { ChangeEvent, ReactNode } from "react"
import { UseFormRegisterReturn } from "react-hook-form"

interface SquareCheckboxProps {
  id: string
  name?: string
  label?: string | ReactNode
  className?: string
  labelClassName?: string
  type?: "square" | "check"
  checked?: boolean
  disabled?: boolean
  hideLabel?: boolean
  handleChange?: (value: boolean) => void
  registeredField?: UseFormRegisterReturn
}

export const SquareCheckbox = ({
  id,
  name,
  checked,
  className,
  label = "",
  labelClassName,
  type = "square",
  disabled = false,
  hideLabel = false,
  handleChange,
  registeredField,
}: SquareCheckboxProps) => {
  const checkboxColor = "blue-500"
  const squareShape = (
    <div
      data-testid="square-shape"
      className={`w-2.5 h-2.5 rounded-sm bg-${checkboxColor}`}
    />
  )

  const borderColor =
    type === "check" && checked ? `border-${checkboxColor}` : "border-black"

  return (
    <div className={classNames("inline-flex items-center", className)}>
      <label
        className="relative flex items-center p-2.5 rounded-full cursor-pointer"
        htmlFor={id}
      >
        <input
          aria-label={`${checked ? "Uncheck" : "Check"} ${label}`}
          type="checkbox"
          className={classNames(
            "before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity",
            { [`bg-${checkboxColor}`]: type === "check" && checked },
            { "opacity-50": disabled },
            borderColor
          )}
          id={id}
          name={name}
          checked={checked}
          data-testid={id}
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChange?.(e.target.checked)
          }}
          {...registeredField}
        />
        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100 flex items-center justify-center">
          {type === "square" ? squareShape : <TickIcon className="h-4 w-4" />}
        </span>
      </label>
      <label
        className={classNames(
          "mt-px cursor-pointer select-none font-medium",
          labelClassName,
          {
            "text-base-300": disabled,
            "sr-only": hideLabel,
          }
        )}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}
